"use client";
import { useState, useCallback, useEffect } from "react";
import { signInWithEmail, signInWithPassword, signInWithOtp, signInWithGoogle } from "./actions";
import { Input } from "@nextui-org/input";
import { Button } from "@nextui-org/button";
import { Tabs, Tab } from "@nextui-org/tabs";

import { useSearchParams } from "next/navigation";
import { motion } from "framer-motion";
import { ArrowRightIcon } from "lucide-react";
import Link from "next/link";
import { createClient } from "@/utils/supabase/client";
import type { EmailOtpType } from "@supabase/supabase-js";
import { Loading } from "@stunt-double/ui";
import { useRouter } from "next/navigation";
import { StuntDoubleIcon } from "@/components/icons";
import { GoogleIcon } from "@/components/navigation/social";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      duration: 0.3,
      ease: "easeOut",
    },
  },
};

interface EmailFormProps {
  email: string;
  setEmail: (value: string) => void;
  onSubmit: (e: React.FormEvent) => Promise<void>;
  buttonText: string;
  onSignInWithGoogle: (e: React.FormEvent) => Promise<void>;
  showGoogle?: boolean;
}

function EmailForm({
  email,
  setEmail,
  onSubmit,
  buttonText,
  onSignInWithGoogle,
  showGoogle = false,
}: EmailFormProps) {
  return (
    <div className="flex flex-col gap-4 w-full mt-4">
      <form onSubmit={onSubmit} className="flex w-full mt-4">
        <label className="sr-only" htmlFor="email">
          Email
        </label>
        <div className="flex relative items-center w-full min-w-[280px]">
          <Input
            id="email"
            aria-label="Email"
            value={email}
            onValueChange={setEmail}
            autoFocus
            autoComplete="email"
            placeholder="maeve@stuntdouble.io"
            variant="flat"
            size="lg"
            classNames={{
              input: "text-base pr-12",
              inputWrapper: "h-12 min-w-[280px]",
              base: "min-w-[280px]",
            }}
          />
          <Button
            className="absolute right-1"
            type="submit"
            color="primary"
            size="md"
            variant="solid"
            isDisabled={!email}
            isIconOnly
          >
            <ArrowRightIcon height={16} width={16} />
          </Button>
        </div>
      </form>
      {showGoogle && (
        <Button
          type="button"
          variant="flat"
          className="w-full mt-4 flex items-center gap-4"
          size="md"
          onClick={onSignInWithGoogle}
        >
          <GoogleIcon height={10} width={10} />
          Sign in with Google
        </Button>
      )}
    </div>
  );
}

function OtpForm({
  email,
  otp,
  setOtp,
  onSubmit,
  onBack,
}: {
  email: string;
  otp: string;
  setOtp: (value: string) => void;
  onSubmit: (e: React.FormEvent) => Promise<void>;
  onBack: () => void;
}) {
  return (
    <div className="flex flex-col gap-4 w-full mt-4">
      <p className="text-sm text-default-600 text-center">
        We sent you a code to {email}. Enter it below to sign in.
      </p>
      <form onSubmit={onSubmit} className="flex w-full">
        <label className="sr-only" htmlFor="otp">
          Code
        </label>
        <div className="flex relative items-center w-full min-w-[280px]">
          <Input
            aria-label="Code"
            value={otp}
            onValueChange={setOtp}
            variant="flat"
            size="lg"
            placeholder="Enter code"
            classNames={{
              input: "text-base pr-12",
              inputWrapper: "h-12 min-w-[280px] focus:ring-0",
              base: "min-w-[280px]",
            }}
          />
          <Button
            className="absolute right-1"
            type="submit"
            color="primary"
            size="md"
            variant="solid"
            isDisabled={!otp}
            isIconOnly
          >
            <ArrowRightIcon height={16} width={16} />
          </Button>
        </div>
      </form>
      <Button type="button" variant="light" onClick={onBack} className="w-fit">
        Change email
      </Button>
    </div>
  );
}

function PasswordForm({
  email,
  setEmail,
  password,
  setPassword,
  onSubmit,
}: {
  email: string;
  setEmail: (value: string) => void;
  password: string;
  setPassword: (value: string) => void;
  onSubmit: (e: React.FormEvent) => Promise<void>;
}) {
  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-4 w-full mt-4">
      <div className="flex relative items-center w-full min-w-[280px]">
        <Input
          aria-label="Email"
          value={email}
          onValueChange={setEmail}
          placeholder="maeve@stuntdouble.io"
          autoFocus
          autoComplete="email"
          variant="flat"
          size="lg"
          classNames={{
            input: "text-base",
            inputWrapper: "h-12 min-w-[280px] focus:ring-0",
            base: "min-w-[280px]",
          }}
        />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <div className="flex relative items-center w-full min-w-[280px]">
          <Input
            aria-label="Password"
            type="password"
            value={password}
            onValueChange={setPassword}
            placeholder="********"
            autoComplete="current-password"
            variant="flat"
            size="lg"
            classNames={{
              input: "text-base pr-12",
              inputWrapper: "h-12 min-w-[280px] focus:ring-0",
              base: "min-w-[280px]",
            }}
          />
          <Button
            className="absolute right-1"
            type="submit"
            color="primary"
            size="md"
            variant="solid"
            isDisabled={!email || !password}
            isIconOnly
          >
            <ArrowRightIcon height={16} width={16} />
          </Button>
        </div>
        <Link
          href="/login/account-recovery/"
          className="text-xs text-primary hover:underline self-end"
        >
          Forgot password?
        </Link>
      </div>
    </form>
  );
}

export default function LoginView() {
  const [view, setView] = useState<"email" | "password">("email");
  const [showOtp, setShowOtp] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const searchParams = useSearchParams();
  const msgParam = searchParams.get("msg");
  const type = searchParams.get("type");
  const router = useRouter();

  // set message
  const handleSetMessage = useCallback(
    (msg: string) => {
      setMessage(msg);
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 8000);
    },
    [setMessage, setShowMessage],
  );

  // handle sign in with email
  const handleSignInWithEmail = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      setOtp("");
      try {
        await signInWithEmail(email);
        setShowOtp(true);
      } catch (error) {
        handleSetMessage(`There was an error sending the email code.`);
      } finally {
        setTimeout(() => setLoading(false), 300);
      }
    },
    [email, handleSetMessage],
  );

  // handle sign in with code
  const handleSignInWithOtp = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      try {
        await signInWithOtp(email, otp);
      } catch (error) {
        handleSetMessage(`There was an error verifying the OTP.`);
      } finally {
        setTimeout(() => setLoading(false), 1500);
      }
    },
    [email, otp, handleSetMessage],
  );

  // handle sign in with password
  const handleSignInWithPassword = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      try {
        const { error } = await signInWithPassword(email, password);
        if (error) {
          handleSetMessage(`Invalid email or password. Please try again or reset your password.`);
          setShowMessage(true);
          setTimeout(() => setShowMessage(false), 15000);
        }
      } catch (error) {
        handleSetMessage(`Invalid email or password. Please try again or reset your password.`);
      } finally {
        setTimeout(() => setLoading(false), 1500);
      }
    },
    [email, password, handleSetMessage],
  );
  const handleSignInWithGoogle = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await signInWithGoogle();
      if (data?.url) {
        router.replace(data.url);
      } else {
        handleSetMessage("There was an error signing in with Google.");
      }
      console.log("data", data);
    } catch (error) {
      handleSetMessage((error as Error).message);
    } finally {
      setTimeout(() => setLoading(false), 1500);
    }
  }, []);
  // handle magic link
  const handleVerifyOtp = async ({
    token_hash,
    type,
  }: {
    token_hash: string;
    type: EmailOtpType;
  }) => {
    const supabase = createClient();
    const { data, error } = await supabase.auth.verifyOtp({
      type,
      token_hash,
    });
    if (error) {
      setLoading(false);
      setMessage(`There was an error verifying the OTP`);
      setShowMessage(true);
    }
    console.log("data", data);
    router.replace("/");
    router.refresh();
  };

  // check search params for magic link
  useEffect(() => {
    if (type && type === "magiclink") {
      const token_hash = searchParams.get("token_hash");
      if (token_hash) {
        handleVerifyOtp({ token_hash, type: "magiclink" });
        setLoading(true);
        router.replace("/");
        router.refresh();
      }
    }
    if (msgParam) {
      setMessage(decodeURIComponent(msgParam));
      setShowMessage(true);
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("msg");
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}${newSearchParams.toString() ? `?${newSearchParams}` : ""}`,
      );
    }
  }, [msgParam, searchParams]);

  useEffect(() => {
    const handleValidSession = async () => {
      const supabase = createClient();
      const { data } = await supabase.auth.getUser();
      if (data.user) {
        router.push("/");
        router.refresh();
      }
    };
    handleValidSession();
  }, []);

  return (
    <motion.div variants={container} initial="hidden" animate="show">
      <div className="flex min-h-[calc(100vh-64px)] w-full justify-center">
        <div className="flex flex-col flex-1 px-4 sm:px-8 py-8 items-center">
          <div className="flex w-full justify-center">
            <StuntDoubleIcon height={36} width={36} />
          </div>
          <div className="w-full max-w-[400px]">
            {loading ? (
              <div className="flex justify-center items-center min-h-[200px]">
                <Loading />
              </div>
            ) : (
              <div>
                <Tabs
                  variant="underlined"
                  onSelectionChange={(key) => setView(key as "email" | "password")}
                  selectedKey={view}
                  classNames={{
                    base: "flex flex-col items-center w-full",
                    tabList: "flex gap-4 max-w-[200px] justify-center",
                    cursor: "w-full border-b-2 border-foreground text-foreground",
                    tab: "mx-2 h-10",
                    tabContent: "text-default-600 group-data-[selected]:text-foreground",
                  }}
                >
                  <Tab key="email" title="Email">
                    {showOtp ? (
                      <OtpForm
                        email={email}
                        otp={otp}
                        setOtp={setOtp}
                        onSubmit={handleSignInWithOtp}
                        onBack={() => setShowOtp(false)}
                      />
                    ) : (
                      <EmailForm
                        email={email}
                        setEmail={setEmail}
                        onSubmit={handleSignInWithEmail}
                        buttonText="Email Code to Sign In"
                        onSignInWithGoogle={handleSignInWithGoogle}
                      />
                    )}
                  </Tab>

                  <Tab key="password" title="Password">
                    <PasswordForm
                      email={email}
                      setEmail={setEmail}
                      password={password}
                      setPassword={setPassword}
                      onSubmit={handleSignInWithPassword}
                    />
                  </Tab>
                </Tabs>

                {message && (
                  <div
                    className={`bg-warning-50 p-4 rounded-lg text-sm text-warning-500 transition-all duration-300 ease-in-out ${
                      showMessage ? "opacity-100 max-h-20" : "opacity-0 max-h-0 overflow-hidden"
                    }`}
                  >
                    {message}
                  </div>
                )}

                <Footer />
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

function Footer() {
  return (
    <div className="pt-4">
      <p className="text-xs text-default-400 w-full text-center">
        Our commitment to{" "}
        <Link href="/legal/privacy" className="text-primary hover:underline">
          data security and privacy
        </Link>
      </p>
    </div>
  );
}
